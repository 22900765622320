body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

/* EMULATOR */
.firebase-emulator-warning {
  display: none;
}

/* ALERTS */
.alert-default {
  background-color: #ffffff !important;
  color: #c43e00 !important;
  border-radius: 25px !important;
}
.alert-default .MuiAlert-icon {
  display: grid;
  place-items: center;
}
.fill-automation-alert {
  position: absolute !important;
}
@media (max-width: 599px) {
  .verifyEmailAlert {
    top: 24px !important;
    right: 24px !important;
    left: 24px !important;
  }
}

/* APP GRID */
.grid {
  display: grid;
  grid-template-rows: 88px auto 70px;
  grid-template-areas:
    "header"
    "feed"
    "navbar";
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.grid-default {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 959px) {
  .grid-default .navbar-grid,
  .grid-default .uh-grid,
  .grid-default .header-grid {
    display: none;
  }
}
.grid-with-tabs {
  display: grid;
  grid-template-rows: 88px 50px auto 70px;
  grid-template-areas:
    "header"
    "tabs"
    "feed-with-tabs"
    "navbar";
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header-grid {
  grid-area: header;
  z-index: 10;
}
.feed-grid {
  grid-area: feed;
  z-index: 1;
  width: 100%;
  position: relative;
}
.tabs-grid {
  grid-area: tabs;
  z-index: 9;
  display: flex;
  align-items: center;
}
.feed-with-tabs-grid {
  grid-area: feed-with-tabs;
  z-index: 8;
}
.navbar-grid {
  grid-area: navbar;
  z-index: 1300;
  bottom: 0;
  width: 100%;
  height: 70px;
  position: fixed;
}
@media (min-width: 960px) {
  .navbar-grid {
    z-index: 1300;
  }
}
.grid-default.orange .navbar-grid {
  background-color: #ff6600;
}
.uh-grid {
  grid-area: UH;
  z-index: 9;
}
.quick-actions-grid > .MuiGrid-item {
  margin: 0 0.5rem;
}
@media (min-width: 960px) {
  .grid {
    grid-template-columns: 74px calc(100vw - 524px) 450px;
    grid-template-rows: 80px auto;
    grid-template-areas:
      "navbar feed header"
      "navbar feed UH";
  }

  .grid-default {
    display: grid;
    grid-template-columns: 74px calc(100vw - 524px) 450px;
    grid-template-rows: 80px auto;
    grid-template-areas:
      "navbar feed header"
      "navbar feed UH";
  }

  .grid-with-tabs {
    grid-template-columns: 74px calc(100vw - 524px) 450px;
    grid-template-rows: 80px auto auto;
    grid-template-areas:
      "navbar tabs header"
      "navbar feed-with-tabs UH"
      "navbar feed-with-tabs UH";
  }

  .navbar-grid {
    bottom: inherit;
    width: inherit;
    min-height: 100svh;
    position: inherit;
  }
}
@media (min-width: 960px) and (max-width: 1090px) {
  .grid {
    grid-template-columns: 74px calc(100vw - 474px) 400px;
    grid-template-rows: 80px auto;
    grid-template-areas:
      "navbar feed header"
      "navbar feed UH";
  }
  .grid-default {
    display: grid;
    grid-template-columns: 74px calc(100vw - 474px) 400px;
    grid-template-rows: 80px auto;
    grid-template-areas:
      "navbar feed header"
      "navbar feed UH";
  }
  .grid-with-tabs {
    grid-template-columns: 74px calc(100vw - 474px) 400px;
    grid-template-rows: 80px auto auto;
    grid-template-areas:
      "navbar tabs header"
      "navbar feed-with-tabs UH"
      "navbar feed-with-tabs UH";
  }
}
@media (min-width: 800px) and (max-width: 960px) {
  .initial-tabs.tabs-grid {
    margin: 0 auto !important;
  }
}

/* END SPACE */
.end-space {
  width: 100%;
  min-height: 110px;
}
@media (min-width: 960px) {
  .end-space {
    display: none;
  }
}

/* SCROLL BAR */

/* Only desktop devices */
@media (hover: hover) {
  .scroll::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }

  .scroll::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  .scroll::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  .scroll::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

/* TEXT-FIELD */
.btn-visibility:hover,
.btn-visibility:focus {
  background-color: inherit !important;
}
.default-text-field .MuiInput-root {
  border: 2px solid #b6b6b6;
  border-radius: 22px;
}
.default-text-field .MuiInput-root.Mui-disabled {
  background-color: rgba(102, 102, 102, 0.27);
}
.default-text-field.white .MuiInput-root {
  border: 2px solid #ffffff;
  color: #ffffff;
}
.default-text-field .MuiInput-root.Mui-error {
  border: 2px solid #f44336;
}
.default-text-field.white .MuiInput-root.Mui-error {
  border: 2px solid #c43e00;
}
.default-text-field .MuiInput-root .MuiInput-input {
  border-radius: 18px;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 38px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.default-text-field .MuiInput-root .MuiInput-input::placeholder {
  font-size: 14px;
  line-height: normal;
}
.default-text-field .MuiInput-root .MuiInputAdornment-root {
  max-height: inherit;
}
.default-text-field.multiline .MuiInput-root .MuiInput-input {
  height: inherit;
}
.default-text-field .MuiInputLabel-root {
  padding-left: 1rem !important;
  padding-top: 0.75rem !important;
  font-size: 16px !important;
  font-weight: 500;
  white-space: nowrap;
}
.default-text-field .MuiFormHelperText-root {
  padding-left: 0.75rem !important;
  padding-top: 0.25rem !important;
  font-size: 16px !important;
  font-weight: 500;
}
.default-text-field.white .MuiInputLabel-root {
  color: #ffffff;
}
.default-text-field .MuiFormHelperText-root.Mui-error,
.default-text-field .MuiInputLabel-root.Mui-error {
  color: #c43e00;
}
.default-text-field .MuiInputLabel-root.Mui-focused,
.default-text-field .MuiInputLabel-root.MuiInputLabel-shrink {
  padding-top: inherit !important;
}

/* FORMS */
.MuiListItem-root.Mui-selected {
  width: 100% !important;
}
.form-edit {
  width: 100%;
}
.form-edit .MuiInputBase-root,
.MuiMenu-paper,
.select-edit .MuiSelect-root {
  border-radius: 20px !important;
}
.form-edit {
  width: 100%;
}
.form-edit .MuiInputBase-root,
.MuiMenu-paper,
.select-edit .MuiSelect-root {
  border-radius: 20px !important;
}
.select-edit .MuiSelect-root {
  width: 70%;
}
@media (min-width: 300px) {
  .select-edit .MuiSelect-root {
    width: 73%;
  }
}
@media (min-width: 400px) {
  .select-edit .MuiSelect-root {
    width: 80%;
  }
}
@media (min-width: 500px) {
  .select-edit .MuiSelect-root {
    width: 82%;
  }
}
@media (min-width: 550px) {
  .select-edit .MuiSelect-root {
    width: 85%;
  }
}
.menu-item-select {
  width: 100%;
}

/* POPUPS */
.dialog .MuiDialog-container .MuiDialog-paper {
  border-radius: 29px;
  overflow: hidden;
}

.dialog.agree-popup,
.dialog.agree-popup div[role="dialog"] {
  max-width: 355px !important;
  margin: 0 auto !important;
}

/* AUTOMATION */
@media (max-height: 700px) {
  .question-automation {
    font-size: 14px;
  }
}
@media (min-height: 600px) {
  .container-automations {
    min-height: calc(100svh - 110px);
    display: grid;
    place-items: center;
  }
}

/* INTRO */
.MuiMobileStepper-dotActive {
  background-color: #ff6600 !important;
}
.stepper-intro .MuiMobileStepper-dots {
  width: 50%;
  justify-content: space-between;
}
@media (max-height: 700px) {
  .label-intro {
    font-size: 0.9rem !important;
    line-height: 1.7rem !important;
  }
  .grid-label-intro {
    min-height: 108px !important;
  }
}
@media (max-height: 600px) {
  .label-intro {
    font-size: 0.8rem !important;
    line-height: 1.1rem !important;
  }
  .grid-label-intro {
    min-height: 70px !important;
  }
}

/* SIGN IN */
.background {
  opacity: 1 !important;
  transform: translate3d(-50%, 0.496499px, 0px) !important;
}

/* FEEDBACK */
.mensagem-feedback .MuiOutlinedInput-root {
  border: 3px solid #666666;
  border-radius: 25px !important;
}
.mensagem-feedback .MuiOutlinedInput-root.Mui-focused {
  border: inherit;
}
.mensagem-feedback .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: none;
}

.mensagem-feedback
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 3px solid #ff6600;
}

@media (min-width: 960px) {
  .mgtp {
    margin-top: 1.5rem !important;
  }
}

@media (max-width: 450px) {
  .icon-feedback {
    width: 55% !important;
  }

  .icon-smile {
    width: 42px !important;
  }
}

@media (max-width: 380px) {
  .texto-feedback-1 {
    font-size: 1.6rem !important;
  }

  .texto-feedback-2 {
    font-size: 0.9rem !important;
  }

  .icon-feedback {
    width: 50% !important;
  }

  .icon-smile {
    width: 40px !important;
  }
}

/* BUTTONS */

.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.marquee span {
  display: inline-block;
  padding-left: 160%;
  animation: marquee 5.5s linear infinite;
}

.marquee2 span {
  animation-delay: 2.75s;
}

.social-btn-in-sign-up:hover,
.social-btn-in-sign-up:focus {
  background-color: #fff !important;
}

.default-button.MuiButton-containedSecondary {
  color: #666666 !important;
}

.default-button.MuiButton-containedSecondary:hover {
  background-color: inherit !important;
}

.MuiFab-root.not-selected:hover {
  background-color: #e5e5e5;
}

.MuiFab-root.selected:hover {
  background-color: #ffffff;
}

.MuiFab-root.showing {
  cursor: inherit;
}

.MuiFab-root.showing:hover {
  background-color: #ffffff;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

@media (min-width: 360px) {
  .marquee span {
    padding-left: 150%;
  }
}

@media (min-width: 375px) {
  .marquee span {
    padding-left: 135%;
  }
}

@media (min-width: 410px) {
  .marquee span {
    padding-left: 125%;
  }
}

/* CARDS */

@media (max-width: 350px) {
  .circle-action-card {
    width: 60px !important;
    height: 60px !important;
  }

  .circle-action-card > svg {
    font-size: 35px;
  }
}

@media (max-width: 400px) {
  .square-document-card {
    width: 105px !important;
    height: 105px !important;
  }

  .square-document-card > svg {
    font-size: 70px;
  }

  .title-document-card {
    font-size: 1rem !important;
  }
}

.not-viewed {
  position: relative;
}

.not-viewed::after {
  content: "";
  width: 32px;
  height: 32px;
  background-color: #ff6600;
  border: 4px solid #c43e00;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}

.paper-step-card:hover {
  box-shadow: none !important;
  background-color: #f4f4f4 !important;
}

.step-card-red {
  position: relative;
}

.step-card-red::after {
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: #ff6600;
  border: 5px solid #c43e00;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  content: "";
}

.MuiExpansionPanelSummary-root {
  padding: 0 2rem !important;
}

.part-card-checkbox .MuiFormControlLabel-label {
  font-weight: 600;
  font-size: 20px;
  margin-left: 1rem;
}

.part-card-checkbox {
  width: 100%;
  height: 100%;
}

.part-selected,
.part-selected .part-card-checkbox .MuiFormControlLabel-label,
.part-selected .MuiAccordionSummary-expandIcon,
.part-selected .MuiTypography-root {
  color: #ffffff !important;
}

.MuiAccordion-root:before {
  display: none;
}

.clause-accordion.Mui-expanded {
  margin: 0 !important;
}

@media (max-width: 405px) {
  .text-information-card {
    font-size: 12px !important;
  }

  .icon-information-card {
    width: 65px !important;
    height: 65px !important;
  }

  .paper-information-card {
    padding: 1rem !important;
  }
}

@media (max-width: 320px) {
  .icon-information-card {
    width: 55px !important;
    height: 55px !important;
  }

  .text-information-card {
    font-size: 11px !important;
  }
}

/* DRAWERS */

@media (max-height: 600px) {
  .container-carteira {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.drawer .MuiDrawer-paper {
  top: 80px;
}

.drawer.small .MuiDrawer-paper {
  top: initial;
  bottom: 0;
}

.drawer-intern div[tabindex="-1"] {
  border-radius: 25px 25px 0 0;
}

.popup-intern .MuiDialog-container .MuiDialog-paper {
  border-radius: 29px;
  padding: 1.5rem;
  margin: 16px;
}

.popup-sign .MuiDialog-container .MuiDialog-paper {
  max-width: 375px;
}

.sign-popup .MuiDialog-container .MuiDialog-paper {
  width: 95vw !important;
  height: 95vh !important;
  max-width: none;
  justify-content: center;
}

@media (min-width: 960px) {
  .drawer-intern,
  .popup-intern {
    margin: 0 450px 0 74px;
    z-index: 1299 !important;
  }

  .drawer-intern div[tabindex="-1"] {
    margin: 0 450px 0 74px;
    border-radius: 45px 45px 0 0;
  }

  .drawer-intern .MuiBackdrop-root,
  .popup-intern .MuiBackdrop-root {
    margin: 0 450px 0 44px;
  }

  .popup-intern .MuiDialog-container .MuiDialog-paper {
    max-width: 400px;
  }
}
@media (min-width: 960px) and (max-width: 1090px) {
  .drawer-intern,
  .popup-intern {
    margin: 0 400px 0 74px;
    z-index: 1299 !important;
  }

  .drawer-intern div[tabindex="-1"] {
    margin: 0 400px 0 74px;
    border-radius: 45px 45px 0 0;
  }

  .drawer-intern .MuiBackdrop-root,
  .popup-intern .MuiBackdrop-root {
    margin: 0 400px 0 44px;
  }
}

@media (min-width: 1100px) {
  .drawer-intern div[tabindex="-1"] {
    margin: 0 500px 0 124px;
  }
}

@media (min-width: 1200px) {
  .drawer-intern div[tabindex="-1"] {
    margin: 0 550px 0 174px;
  }
}

@media (min-width: 1300px) {
  .drawer-intern div[tabindex="-1"] {
    margin: 0 600px 0 224px;
  }
}

/* HEADER */

.MuiButton-colorInherit {
  color: #959595 !important;
}

.drawer div[tabindex="-1"] {
  border-radius: 55px 55px 0 0;
  width: 100vw;
  margin: 0;
  overflow: hidden;
}

.drawerNavqa div[tabindex="-1"] {
  border-radius: 30px 30px 0 0;
}

.transactions-list {
  height: 315px;
}

.drawer .Mui-Tabs-scroller {
  color: #ffffff;
}

@media (min-height: 640px) {
  .transactions-list {
    height: 380px !important;
  }
}

@media (min-height: 667px) {
  .transactions-list {
    height: 400px !important;
  }
}

@media (min-height: 731px) {
  .transactions-list {
    height: 457px !important;
  }
}

@media (min-height: 812px) {
  .transactions-list {
    height: 530px !important;
  }
}

@media (min-height: 823px) {
  .transactions-list {
    height: 535px !important;
  }
}

@media (min-width: 960px) {
  .drawer div[tabindex="-1"] {
    width: 450px;
    margin: 0 0 0 auto;
    overflow: hidden !important;
  }
}

@media (min-width: 960px) and (max-width: 1090px) {
  .drawer div[tabindex="-1"] {
    width: 400px;
    margin: 0 0 0 auto;
    overflow: hidden !important;
  }
}

/* NAVBAR */

.MuiBottomNavigationAction-root {
  padding: 0px !important;
  min-width: inherit !important;
  flex: inherit !important;
}

.MuiBottomNavigationAction-label {
  color: #fff;
  display: none !important;
  padding-left: 0.5rem;
  font-weight: bold;
  font-size: 16px !important;
}

.MuiBottomNavigationAction-wrapper {
  flex-direction: row !important;
}

.Mui-selected {
  display: inline-flex !important;
}

.Mui-selected .MuiBottomNavigationAction-wrapper {
  background-color: rgba(0, 0, 0, 10%);
  border-radius: 15px;
  min-height: 40px;
  padding: 0 1rem;
}

@media (min-width: 960px) {
  .MuiBottomNavigationAction-label {
    display: none !important;
  }

  .Mui-selected .MuiBottomNavigationAction-wrapper {
    background-color: rgba(0, 0, 0, 10%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  .MuiBottomNavigationAction-root {
    min-width: inherit !important;
    width: 100% !important;
    height: 15vh !important;
  }

  .MuiBottomNavigation-root:hover {
    width: 180px !important;
    z-index: 3000 !important;
  }

  .MuiBottomNavigation-root:hover .MuiBottomNavigationAction-label {
    display: block !important;
  }

  .MuiBottomNavigation-root:hover
    .Mui-selected
    .MuiBottomNavigationAction-wrapper {
    border-radius: 15px;
    height: 35px;
    padding: 0.3em 0.5em;
    width: auto;
  }

  .MuiBottomNavigation-root:hover .MuiBottomNavigationAction-wrapper {
    justify-content: flex-start;
  }
}

/* STEPPER */
.MuiStepConnector-lineHorizontal::after,
.MuiStepConnector-lineHorizontal::before {
  content: "";
  width: 15px;
  height: 4px;
  position: absolute;
  top: 0;
  z-index: 1;
  background-color: inherit;
}

.MuiStepConnector-lineHorizontal::before {
  left: -14px;
}

.MuiStepConnector-lineHorizontal::after {
  right: -14px;
}

/* USER-HELPER */
@media (min-height: 700px) {
  .titulo-helper {
    font-size: 20px !important;
  }

  .texto-helper {
    font-size: 16px !important;
  }

  .root-helper {
    margin: 1.5em auto !important;
  }
}

/* MENTORING */

.mentoring-progress .MuiLinearProgress-bar::after {
  content: "";
  border: 7px solid #666666;
  background-color: #ffffff;
  border-radius: 50%;
  width: 25px;
  height: 100%;
  position: absolute;
  right: -12.5px;
}

/* CONFETTI */
.app-confetti {
  width: 100%;
  height: 100%;
  z-index: 1402 !important;
}
